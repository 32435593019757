import Modal from 'styleguide/components/Modal/Modal';
import * as React from 'react';
import { Address } from 'bundles/App/pages/Account/Addresses/types';
import { Formik, Form, FormikValues } from 'formik';
import { number, object } from 'yup';
import { Label, RadioGroup } from '@headlessui/react';
import AddressItem from 'bundles/App/pages/OrderDetailsPage/Address/Address';
import { Button, RadioGroupButton } from 'styleguide/components';
import { dpvMatchCodeErrors } from 'contexts/ValidateAddressContext/ValidateAddressContextContainer';

const ValidatedAddressList = ({ validatedAddresses, value, onChange, dpvMatchCode }) => (
  <RadioGroup value={value} onChange={onChange} className="mt-2 max-h-72 overflow-y-scroll scrollbar-hide">
    <Label className="sr-only relative text-sm text-gray-500">
      {dpvMatchCode === 'Y' ? 'Select a Valid Address' : 'Select An Address'}
    </Label>
    <div>
      {validatedAddresses.map((address, i) => (
        <RadioGroupButton
          value={i}
          key={i.toString()}
          label={<AddressItem address={address} withTitles />}
          className="mb-3"
        />
      ))}
    </div>
  </RadioGroup>
);

const ActionButtons = ({
  validStreet,
  onClose,
  selectAddressWithoutSaving,
  onAdminUseUnVerifiedAddress,
  admin,
}) => (
  <div className="w-full mt-3 flex justify-end gap-1">
    <Button data-cy="cancelBtn" type="button" color="dark" outline onClick={onClose}>
      Cancel
    </Button>
    {validStreet && (
      <Button type="submit" color="blue" data-cy="confirmBtn">
        {selectAddressWithoutSaving ? 'Select Validated Address' : 'Save Validated Address'}
      </Button>
    )}
    {!validStreet && (
      <Button type="submit" color="blue" data-cy="confirmBtn">
        Use Unverified Address
      </Button>
    )}
    {validStreet && admin && (
      <Button data-cy="confirmBtn" type="button" color="blue" onClick={onAdminUseUnVerifiedAddress}>
        Use Unverified Address
      </Button>
    )}
  </div>
);

interface Props {
  admin: boolean;
  selectAddressWithoutSaving: boolean;
  onClose: () => void;
  onSaveValidAddress: (address: Address) => void;
  unverifiedAddress: Address;
  validatedAddresses: Address[];
  dpvMatchCode: 'N' | 'Y' | 'D' | 'S';
}

const ValidatedAddressesModal = ({
  admin,
  onClose,
  onSaveValidAddress,
  unverifiedAddress,
  validatedAddresses,
  selectAddressWithoutSaving,
  dpvMatchCode,
}: Props) => {
  const validStreet = validatedAddresses?.[0].verified;
  const onSubmit = (values: FormikValues) => {
    onSaveValidAddress(validatedAddresses[values.index]);
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      shown
      className="w-full !max-w-4xl"
      title={
        <h2 className="!font-hvBold !text-2xl" data-cy="title">
          Select a Validated Address
        </h2>
      }
    >
      <Formik
        initialValues={{ index: 0 }}
        onSubmit={values => onSubmit(values)}
        validationSchema={object().shape({ index: number().required() })}
      >
        {formikProps => (
          <Form>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2">
                <div className="font-hvBold text-xl text-gray-700 mb-3" data-cy="currentAddressHeading">
                  Current Address:
                </div>
                <AddressItem address={unverifiedAddress} withTitles />
              </div>
              <div className="md:w-1/2">
                {dpvMatchCode === 'Y' ? (
                  <div
                    className="font-hvBold text-xl text-gray-700 -lg:mt-3"
                    data-cy="validatedAddressesHeading"
                  >
                    Validated Addresses:
                  </div>
                ) : (
                  <div data-cy="couldntValidateStreetAddressText" className="mt-2 text-red-600">
                    {`${dpvMatchCodeErrors[dpvMatchCode]}. If its correct, please
                  choose an unverified address from below to continue`}
                  </div>
                )}
                <ValidatedAddressList
                  validatedAddresses={validatedAddresses}
                  value={formikProps.values.index}
                  onChange={value => formikProps.setFieldValue('index', value)}
                  dpvMatchCode={dpvMatchCode}
                />
              </div>
            </div>
            <ActionButtons
              validStreet={validStreet}
              onClose={onClose}
              onAdminUseUnVerifiedAddress={() => {
                onSaveValidAddress(unverifiedAddress);
                onClose();
              }}
              selectAddressWithoutSaving={selectAddressWithoutSaving}
              admin={admin}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ValidatedAddressesModal;
